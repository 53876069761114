

import React from 'react'
import { useLocation } from 'react-router';

const Location = () => {
    const location = useLocation()
    const currentPath = location?.pathname
    //console.log('currentPath', currentPath)
    return currentPath
}

export default Location