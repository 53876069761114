import React, { useState } from 'react'
import Display from 'Components/Display';
import GlobalLoading from 'Components/GlobaLoding';
import InputEntry from 'Components/Entries/InputEntry';
import { GetGeneratedScriptFromGoogleCollabScript } from 'Api/Development';
import { Dservices } from 'Components/Services';
import Location from '../../Components/Location';

const GenerateScriptFromGoogleCollabScript = () => {
    const [script, setScript] = useState(null);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = GlobalLoading()

    const currentPath = Location()
    

    const HandleChange = (e) => {
        setScript(e.target.value)
    }

    const StartScriptGeneration = async () => {
        setError(null);
            setIsLoading(true);
            try {
                if (script) {
                    const { data } = await GetGeneratedScriptFromGoogleCollabScript({script});
                    //console.log(data)
                    if (data) {
                        setResult(data);
                    }
                }
            } catch (error) {
                setError(error?.response?.data?.message?.message)
            }
            finally {
                setIsLoading(false);
            }
    }

    return (
        <Display
            result={result}
            isLoading={isLoading}
            error={error}
            title={Dservices.find(s => s.path === currentPath)?.title}
        >
            <InputEntry
                type="textarea"
                value={script}
                labelBtn={"Commencer"}
                label={"Mettre le script de google Colab"}
                error={error}
                handleClick={StartScriptGeneration}
                HandleChange={HandleChange}
            />
        </Display>
    )
}

export default GenerateScriptFromGoogleCollabScript