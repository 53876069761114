import Layout from './Layout/Layout'
import { Card, CardBody, CardHeader, CardImg, Container, Row, Spinner } from 'reactstrap'
import AlertMessage from './AlertMessage'
import TestDevImg from '../assets/img/screenshot.png'
import config from 'config'

const Display = ({ result, isLoading, error, children,title }) => {

    return (
        <Layout>
            <Container className='mb-2 p-2'>
                <h4 className='text-center'>{title || ''}</h4>

            </Container>
            <Container className="shadow p-5 mb-5 bg-white rounded">

                {children}
                <Row className='justify-content-center'>
                    {error && <AlertMessage color='danger' message={error} />}
                    {isLoading ?
                        <Spinner
                            color="primary"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        :
                        result && (
                            result.analysis_result ? (
                                <Card>
                                    <CardHeader icon="primary">
                                        <h6>{result?.site_url}</h6>
                                    </CardHeader>
                                    <CardBody style={{ whiteSpace: 'pre-line' }}>
                                        {result?.analysis_result}
                                    </CardBody>
                                </Card>
                            ) : result.path ? (
                                <Card>
                                    <CardBody className="text-center">
                                        <Row className="justify-content-center">
                                            <CardImg
                                                style={{
                                                    alignSelf: "center",
                                                    // objectFit: "cover",
                                                }}
                                                alt="Capture"
                                                src={
                                                    /*config.Environment === 'dev'
                                                        ? TestDevImg
                                                        : */`${config.WS_BASE_URL3}${result.path}`
                                                }
                                            />
                                        </Row>
                                    </CardBody>
                                </Card>
                            ) : result.transcription ? (
                                <Card>
                                    <CardHeader icon="primary">
                                        <h6>Transcription</h6>
                                    </CardHeader>
                                    <CardBody style={{ whiteSpace: 'pre-line' }}>
                                        {result?.transcription}
                                    </CardBody>
                                </Card>
                            ) : null
                        )


                    }
                </Row>
                {/* <Row>
                    <Input type='file' onChange={HandleFileChange} />
                    <Button onClick={handleFileClick}>Upload</Button>
                
                </Row> */}
            </Container>
        </Layout>
    )
}

export default Display