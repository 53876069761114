import React, { useState } from 'react'
import GlobalLoading from 'Components/GlobaLoding';
import Display from 'Components/Display';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { CreateLinkedin_Advertisement } from 'Api/Marketing';
import { Mservices } from 'Components/Services';
import Location from 'Components/Location';

const CreateLinkedinAdvertisement = () => {
    const [page_web_url, setPage_web_url] = useState(null);
    const [nb_max_content, setNb_max_content] = useState(null);
    const [language, setLanguage] = useState(null);

    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = GlobalLoading()

    const currentPath = Location()


    const HandleChange = (e, setter) => {
        setter(e.target.value)
    }


    /* const HandleWebURLValidation = () => {
        const regex = /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-./?%&=]*)?$/;
        if (!regex.test(page_web_url))) {
            setError('Lien non valide!')
        }
        //console.log('test', regex.test(url))
        return (regex.test(page_web_url));
    } */

    const handleFormValidation = () => {
        let isValid = true;
        let errorMessage = '';
        const regex = /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-./?%&=]*)?$/;

        if (!regex.test(page_web_url)) {
            isValid = false;
            errorMessage = 'Lien non valide!'
        }

        if (!page_web_url) {
          isValid = false;
          errorMessage ="champs de l'URL de page web est requis";
        }
        
        if (!nb_max_content) {
          isValid = false;
          errorMessage ="Le nombre max de caractère est requis ";
        }
        
        if (!language) {
          isValid = false;
          errorMessage ="champs de langue requis ";
        }

        if(!isValid){
            setError(errorMessage)
        }
        
        return isValid;
      };

    const OpenAIAnalysis = async () => {
        setError(null);
        if (handleFormValidation()) {
            setIsLoading(true);
            try {
                const { data } = await CreateLinkedin_Advertisement(page_web_url, nb_max_content, language);
                //console.log(data)
                if (data) {
                    setResult(data);
                }
            } catch (error) {
                setError(error?.response?.data?.message?.message)
            }
            finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <Display
            result={result}
            isLoading={isLoading}
            error={error}
            title={Mservices.find(s => s.path === currentPath)?.title}

        >
                <Row className="justify-content-center shadow-none p-5 bg-light rounded mb-5">
                    <Col sm='3'>
                        <Label>Mettre l' URL d'une page Web</Label>
                        <Input
                            name="url"
                            type='url'
                            placeholder="www.exemple.com"
                            onChange={(e) => HandleChange(e, setPage_web_url)}
                        />
                    </Col>
                    <Col sm='3'>
                        <Label>Le nombre max de caractère</Label>
                        <Input
                            type='number'
                            placeholder="exemple : 600"
                            onChange={(e) => HandleChange(e, setNb_max_content)}
                        />
                    </Col>
                    <Col sm='3'>
                        <Label>Choisir la langue de la publicité</Label>
                        <Input
                            placeholder="exemple:français"
                            onChange={(e) => HandleChange(e, setLanguage)}
                        />
                    </Col>
                    <Col sm="2">
                    <h1></h1>
                    <Button
                        className="w-100 mt-5"
                        color='primary'
                        onClick={OpenAIAnalysis}
                        disabled={!page_web_url || !nb_max_content || !language}
                    >
                        Etudier
                    </Button>
                    </Col>
                </Row>
        </Display>
    )
}

export default CreateLinkedinAdvertisement