import React, { useState } from 'react'
import GlobalLoading from 'Components/GlobaLoding';
import Display from 'Components/Display';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { GoNoForProjectDevelopment } from 'Api/Strategy';
import { Sservices } from 'Components/Services';
import Location from 'Components/Location';

const GoNoToStartProjectDevelopment = () => {
    const [idea, setIdea] = useState(null);
    const [core, setCore] = useState(null);
    const [bestBuyer, setBestBuyer] = useState(null);

    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = GlobalLoading()

    const currentPath = Location()


    const HandleChange = (e, setter) => {
        setter(e.target.value)
    }

    const handleFormValidation = () => {
        let isValid = true;
        let errorMessage = '';

        if (!idea) {
            isValid = false;
            errorMessage = "champs de l'URL de page web est requis";
        }

        if (!core) {
            isValid = false;
            errorMessage = "Le champs idée est requis ";
        }

        if (!bestBuyer) {
            isValid = false;
            errorMessage = "champs de langue requis ";
        }

        if (!isValid) {
            setError(errorMessage)
        }

        return isValid;
    };

    const OpenAIAnalysis = async () => {
        setError(null);
        if (handleFormValidation()) {
            setIsLoading(true);
            try {
                const { data } = await GoNoForProjectDevelopment({idea, core, bestBuyer});
                //console.log(data)
                if (data) {
                    setResult(data);
                }
            } catch (error) {
                setError(error?.response?.data?.message?.message)
            }
            finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <Display
            result={result}
            isLoading={isLoading}
            error={error}
            title={Sservices.find(s => s.path === currentPath)?.title}
        >
            <Row className="justify-content-center shadow-none p-5 bg-light rounded">
                <Col>
                    <Label>Mettre votre idée avec ses valeurs ajoutées</Label>
                    <Input
                        rows={3}
                        type='textarea'
                        onChange={(e) => HandleChange(e, setIdea)}
                    />
                </Col>
            </Row>
            <Row className="p-5 bg-light">
                <Col sm='6'>
                    <Label>Le core buisness de votre idée</Label>
                    <Input
                        type='textarea'
                        rows={3}
                        onChange={(e) => HandleChange(e, setCore)}
                    />
                </Col>
                <Col sm='6'>
                    <Label>Meilleur cible/audience</Label>
                    <Input
                        type='textarea'
                        onChange={(e) => HandleChange(e, setBestBuyer)}
                        rows={3}
                    />
                </Col>
                
            </Row>
            <Row className='p-3 mb-5 bg-light'>
                    <Button
                        className="w-100"
                        color='primary'
                        onClick={OpenAIAnalysis}
                        disabled={!idea || !core || !bestBuyer}
                    >
                        Etudier
                    </Button>

                </Row>
        </Display>
    )
}

export default GoNoToStartProjectDevelopment