import CardLists from 'Components/CardLists';
import React from 'react'
import { Sservices } from './index';

const Strategy = () => {

    return (
        <CardLists items={Sservices} />
    )
}

export default Strategy