import CardLists from 'Components/CardLists';
import React from 'react'
import { Pservices } from './index';

const Prospection = () => {

    return (
        <CardLists items={Pservices} />
    )
}

export default Prospection