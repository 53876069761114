import React, { useState } from 'react'
import Display from 'Components/Display';
import InputEntry from 'Components/Entries/InputEntry';
import GlobalLoading from 'Components/GlobaLoding';
import { TranscriptSpeechToText } from 'Api/General';
import { Gservices } from 'Components/Services';
import Location from 'Components/Location';

const SpeechToText = () => {
    const [file, setFile] = useState(null);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = GlobalLoading()

    const currentPath = Location()

    const HandleFileChange= (e)=>{
       setFile(e.target.files[0])
    }

    const handleFileClick= async()=>{
        const formData = new FormData();
        formData.append("file", file)
        try {
            const {data} = await TranscriptSpeechToText(formData)
            setResult(data)
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <Display
            result={result}
            isLoading={isLoading}
            error={error}
            title={Gservices.find(s => s.path === currentPath)?.title}
        >
            <InputEntry
                type="file"
                value={file}
                labelBtn={"Commencer"}
                label={"Mettre un audio"}
                error={error}
                handleClick={handleFileClick}
                HandleChange={HandleFileChange}
            />
        </Display>
    )
}

export default SpeechToText