import React, { useState } from 'react'
import Display from 'Components/Display';
import InputEntry from 'Components/Entries/InputEntry';
import GlobalLoading from 'Components/GlobaLoding';
import { GetLandingPageProspectsAnalysis } from 'Api/Strategy';
import Location from 'Components/Location';
import { Sservices } from 'Components/Services';

const LandingPageProspectsAnalysis = () => {
    const [url, setUrl] = useState(null);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = GlobalLoading()
    
    const currentPath = Location()

    const HandleChange = (e) => {
        setUrl(e.target.value)
    }


    const HandleWebURLValidation = () => {
        const regex = /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-./?%&=]*)?$/;
        if (!regex.test(url)) {
            setError('Lien non valide!')
        }
        //console.log('test', regex.test(url))
        return regex.test(url);
    }

    const StartLandingPageProspectsAnalysis = async () => {
        setError(null);
        if (HandleWebURLValidation()) {
            setIsLoading(true);
            try {
                if (url) {
                    const { data } = await GetLandingPageProspectsAnalysis(url);
                    //console.log(data)
                    if (data) {
                        setResult(data);
                    }
                }
            } catch (error) {
                setError(error?.response?.data?.message?.message)
            }
            finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <Display
            result={result}
            isLoading={isLoading}
            error={error}
            title={Sservices.find(s => s.path === currentPath)?.title}
        >
            <InputEntry
                type="url"
                value={url}
                labelBtn={"Etudier"}
                label={"Mettre l' URL d'une page Web"}
                error={error}
                handleClick={StartLandingPageProspectsAnalysis}
                HandleChange={HandleChange}
                placeholder="www.exemple.com"
            />
        </Display>
    )
}

export default LandingPageProspectsAnalysis