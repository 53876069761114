export  const Dservices = [
    {
        title: "Nettoyer le script google colab",
        description: "Corriger le script Google Colab afin d'avoir un code python qu'on peut installer dans un docker python (fast API)",
        path: "/development/get_deployable_fastAPI_Script_from_googleColab"
    },
    
]

export const Gservices = [
    {
        title: "Capture d'écran d'une page web",
        description: "Capturer la page web complète",
        path: "/general/web_page_screenshot"
    },
    {
        title: "Speech to text",
        description: "Transcription video ou audio en texte",
        path: "/general/speech_to_text"
    },
]

export const Mservices = [
    {
        title: "Etude design & UX",
        description: "Etudier le design et la user experience (UX) d'une page web",
        path: "/marketing/design&ux_study"
    },
    {
        title: "Créer une publication Linkedin",
        description : "Créer une publication Linkedin à travers une page web",
        path: "/marketing/create_linkedin_post"
    },
    {
        title: "Améliorer le contenu Web",
        description : "Changer le contenu d'une page web à partir d'autre web page d'inspiration",
        path: "/marketing/change_web_content_from_inspiration_one"
    },
    {
        title: "Créer une publicité Linkedin",
        description : "Changer une publicité linkedin selon votre site web",
        path: "/marketing/create_linkedin_advertisement"
    },
    {
        title: "Générer de nouveaux pages web",
        description : "Générer de nouveaux pages web pour votre site web",
        path: "/marketing/page_web_suggestions"
    },
    {
        title: "Créer un post Linkedin à travers les meilleures vidéos du moment sur Youtube",
        description : "",
        path: "/marketing/create_linkedin_post_from_popular_youtubeTexts"
    },
    
]

export const Pservices = [
    {
        title: "Trouver des prospects via Linkedin Recruiter",
        description: "Trouver comment les trouver via une recherche sur Linkedin Recruiter",
        path: "/prospection/find_prospects_via_linkedin_recruiter"
    },
    
]

export const Sservices = [
    {
        title: "Etude MVP",
        description: "Etudier le MVP d'un produit/service",
        path: "/strategy/MVP_analysis"
    },
    {
        title: "Etude meilleurs cibles",
        description: "Trouve les meilleurs cibles qui peuvent acheter votre service",
        path: "/strategy/Best_Buyers_analysis"
    },
    {
        title: "Etude Prospects à travers une page web",
        description: "Trouver les meilleurs prospects à cibler à votre landing page",
        path: "/strategy/landingPage_prospects_analysis"
    },
    {
        title: "Recherche des concurrents",
        description: "Chercher s'il y a des concurrents pour votre idée",
        path: "/strategy/Competitors_Study"
    },
    {
        title: "Trouver un nom d'un site web",
        description: "Trouver un nom d'un site web selon votre idée",
        path: "/strategy/generate_webSite_name"
    },
    {
        title: "Rendre le projet profitable",
        description: "Chercher comment rendre le projet profitable",
        path: "/strategy/make_project_profitable"
    },
    {
        title: "Détailler les valeurs ajoutées du projet",
        description: "Brainstorming et étude des valeurs ajoutées",
        path: "/strategy/addedValues_study"
    },
    {
        title: "Canal de prospection pour les segments",
        description: "Canal de prospection pour les segments pour votre idée",
        path: "/strategy/prospecting_channel_for_segments"
    },
    {
        title: "startup Canaux de distribution",
        description: "Etudier les Canaux de distribution possibles",
        path: "/strategy/distribution_channels_study"
    },
    {
        title: "Startup: Etapes à réaliser",
        description: "Etudier les étapes à réaliser",
        path: "/strategy/study_steps_startup"
    },
    {
        title: "Startup: Actuellement comment les utilisateurs travaillent",
        description: "",
        path: "/strategy/find_users_manners"
    },
    {
        title: "Startup: Etude des couts",
        description: "Etude les couts de votre produit/service",
        path: "/strategy/startup_costs_study"
    },
    {
        title: "The Core is the core of the project",
        description: "Etudier le meilleur core du business",
        path: "/strategy/project_core_study"
    },
    {
        title: "Go/No pour entamer le développement du projet",
        description: "Lancier ou non le développement de votre idée",
        path: "/strategy/goNo_to_start_project_development"
    },
    {
        title: "The UX for The Core is the core of the project",
        description: "Définir les termes UX pour votre idée",
        path: "/strategy/ux_project_core_study"
    },
    {
        title: "Plan d'exécution technique idéale",
        description: "Plan d'exécution technique idéale qu'il faut suivre afin de tester ce nouveau service/produit",
        path: "/strategy/technical_execution_plan_for_testing_new_idea"
    },
    {
        title: "Plan d'exécution globale idéale",
        description: "Plan d'exécution globale idéale qu'il faut suivre afin de tester ce nouveau service/produit",
        path: "/strategy/global_execution_plan_for_testing_new_idea"
    }
]

