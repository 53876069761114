import CardLists from 'Components/CardLists';
import React from 'react'
import { Mservices } from './index';

const Marketing = () => {

    return (
        <CardLists items={Mservices} />
    )
}

export default Marketing