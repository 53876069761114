import CardLists from 'Components/CardLists';
import React from 'react'
import { Dservices } from './index';

const Development = () => {

    return (
        <CardLists items={Dservices} />
    )
}

export default Development